.select-input .hidden {
    display: none;
}

.select-input input.valid {
	background-color: #d7eccb;
}

.select-input input.waiting {
	background-color: #fafba5;
}

.select-input input.void {
	background-color: #ffcebf;
}
